import axios from 'util/Api';
import { handleError } from 'util/ApiHelper';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { setAllCustomers, setCustomerData, setEarningList, setAgentList } from '../../redux/actions/Customers';

export const getCustomers = user_id => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .get('customer/' + user_id, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            dispatch(setAllCustomers(data.result));
          } else {
            dispatch(setAllCustomers([]));
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getCustomerData = customer_id => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .get('customer/details/' + customer_id, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            dispatch(setCustomerData(data.result));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getEarningList = body => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('earnings/list', body, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            dispatch(setEarningList(data.result));
          } else {
            dispatch(setEarningList([]));
            dispatch(fetchError(data.error));
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getCouponList = (body, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('discount/list', body, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.error));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getLatestLicence = (userId, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .get('payment_link/user_licence/' + userId, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.error));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const createPaymentLink = (body, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('payment_link/create', body, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.error));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getAgentList = cb => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .get('agent/list', {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            dispatch(setAgentList(data.result));
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(setAgentList([]));
            dispatch(fetchError(data.error));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const activeDeactiveAgent = (body, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('agent/status', body, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};
// /earnings/list

export const inviteAgent = (post, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('agent/invite', post, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getLicenceType = cb => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .get('licence/licencetypes', {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getAllUser = cb => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .get('admin/get_all_user_email', {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const createCoupon = (post, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('discount/coupon', post, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const updateCoupon = (post, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .put('discount/coupon', post, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const deleteCoupon = (id, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .delete('discount/coupon/' + id, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const updateStatusCoupon = (post, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('discount/coupon_status', post, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getAllCoupon = cb => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .get('discount/all_coupon_list', {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getAllBillingInfos = cb => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .get('admin/all_billing_infos', {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const getUserWhichNotInBillingInfo = cb => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .get('admin/get_all_user_for_billing', {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const createBilling = (post, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .post('admin/billing_info', post, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};

export const deleteBillingAddress = (id, cb) => {
  return dispatch => {
    try {
      dispatch(fetchStart());
      axios
        .delete('admin/billing_info/' + id, {
          headers: {
            authorization: localStorage.getItem('token'),
            platform: 'admin',
          },
        })
        .then(({ data }) => {
          if (data && data.result && data.success) {
            dispatch(fetchSuccess());
            if (typeof cb == 'function') cb(data.result);
          } else {
            dispatch(fetchError(data.message));
            if (typeof cb == 'function') cb([]);
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          handleError(error);
        });
    } catch (error) {
      dispatch(fetchError(error.message));
      handleError(error);
    }
  };
};
