import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Login from './Auth/Login';
import Signup from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import AppLayout from './containers/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setInitUrl } from 'redux/actions/Auth';
import jwt from 'jsonwebtoken';
const RestrictedRoute = ({ component: Component, token, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        token && user && user._id == token.user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = props => {
  const { match, history, location } = props;
  const { authUser, initUrl } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  useEffect(() => {
    // window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initUrl === '') {
      dispatch(setInitUrl(history.location.pathname));
    }
  }, [dispatch, initUrl]);

  // const location = useLocation();
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  // console.log(jwt.decode(token), "token");
  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (token && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        {/* <Route path="/signup" component={Signup} /> */}
        <Route path="/forgot-password" component={ForgotPassword} />
        <RestrictedRoute path={`/`} token={jwt.decode(token)} user={user} component={AppLayout} />
        {/* <Route path="/layout-builder" component={LayoutBuilder} /> */}
      </Switch>

      {/* {location.pathname !== '/signin' && location.pathname !== '/signup' && location.pathname !== '/forgot-password' && (
        <TourGuide />
      )} */}
    </React.Fragment>
  );
};

export default Routes;
