import { GET_ALL_LICENCE, GET_ALL_PERIODS } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  licence_list: [],
  periods: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LICENCE: {
      return {
        ...state,
        licence_list: action.payload,
      };
    }

    case GET_ALL_PERIODS: {
      return {
        ...state,
        periods: action.payload,
      };
    }

    default:
      return state;
  }
};
