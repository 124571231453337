import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid,Box,List, ListItem } from '@material-ui/core';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import PropTypes from 'prop-types';

import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSpring, animated } from 'react-spring/web.cjs';
import moment from 'moment';
import { getCustomers } from '../../../services/customers';
import jwt from "jsonwebtoken"

const useStyles = makeStyles(theme => ({
  orderLg2: {
    [theme.breakpoints.up('lg')]: {
      order: 2,
    },
  },
  orderLg1: {
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
  root: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const UserDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const token = localStorage.getItem('token');
  const userData = jwt.decode(token);

  const customerList = useSelector((state)=> state?.customer?.customer_list);
  useEffect(() => {
    dispatch(getCustomers(userData.user));
    return () => {
      
    };
  }, []);

  return (
    <PageContainer heading="Customer List">
      <CmtCard className="mt-6">
        <CmtCardHeader
          className="pt-4"
          title="Customer Details"
          titleProps={{
            variant: 'h4',
            component: 'div',
            className: classes.titleRoot,
          }}
        />

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead >
              <TableRow>
                <TableCell width={100} align="left">Company Name<br />(Email Address)</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell style={{minWidth:180}} align="left">Subscription Info<br />(Users - Price)</TableCell>
                <TableCell style={{minWidth:100}}align="right">Last Payment</TableCell>
                <TableCell style={{minWidth:140}} align="left">Last Payment<br />Date</TableCell>
                <TableCell style={{minWidth:130}} align="left">Renewal<br />Date</TableCell>
                <TableCell style={{minWidth:200}} align="left">Account Owner<br />(Email - Address)</TableCell>
                <TableCell align="left">Mobile</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerList?.map(user => (
                <TableRow onClick={()=> props.history.push(`/transactions/${user._id}`)} key={user._id}>
                  <TableCell align="left">{`${user.first_name} ${user.last_name}`}<br />({user.email})</TableCell>
                  <TableCell align="left">{user?.plans && user?.plans.length > 0 ? `Active` : ""}</TableCell>
                  
                  <TableCell align="left">{user?.plans?.[0]?.licence.type[0].licence_name} ({(user?.plans?.[0]?.count) ? user.plans[0].count : "0"} - {(user?.plans?.[0]?.licence?.amount) ? user.plans[0].licence.amount : "0"})</TableCell>

                  <TableCell align="right">{user?.plans?.[0]?.payment[0] ? Number(user?.plans?.[0]?.payment[0].amount).toFixed(2) : ""}</TableCell>
                  <TableCell align="left">{user?.plans?.[0]?.payment[0]?.createdAt ? moment(user?.plans?.[0]?.payment[0].createdAt).format("YYYY-MM-DD") : ""}</TableCell>
                  <TableCell align="left">{user?.plans?.[0]?.end_date ? moment(user?.plans?.[0]?.end_date).format("YYYY-MM-DD") : ""}</TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">{user.mobile ? user.country_code + user.mobile : ""}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CmtCard>
    </PageContainer>
  );
};

export default UserDetails;
