import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Grid, Box, List, ListItem } from '@material-ui/core';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Swal from 'sweetalert2';
import moment from 'moment';
import { getAgentList, inviteAgent, activeDeactiveAgent } from '../../../services/customers';
import jwt from 'jsonwebtoken';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const Agent = props => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [agentModel, setAgentModel] = useState(false);
  const [email, setEmail] = useState('');

  const token = localStorage.getItem('token');
  const userData = jwt.decode(token);
  const handleAgentModel = () => setAgentModel(!agentModel);

  const agentEmail = e => {
    setEmail(e.target.value);
  };

  const handleAgentInvite = () => {
    dispatch(
      inviteAgent({ email: email }, () => {
        handleAgentModel();
        dispatch(getAgentList(userData.user));
      }),
    );
  };

  const activeDeactiveAgentHandle = (user, e) => {
    let statusVal = e.target.checked;
    Swal.fire({
      title: `Do you want to ${statusVal ? 'active' : 'De-Active'} this agent?`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: statusVal ? 'Activate' : 'De-Active',
      denyButtonText: `No`,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          activeDeactiveAgent({ _id: user._id, status: statusVal }, resData => {
            dispatch(getAgentList());
            Swal.fire(`${statusVal ? 'Activated' : 'De-Active'}`, '', 'success');
          }),
        );
      } else if (result.isDenied) {
        Swal.fire('Agent status is not changed', '', 'info');
      }
    });
  };

  const agentList = useSelector(state => state?.customer?.agent_list);
  useEffect(() => {
    dispatch(getAgentList(userData.user));
    return () => {};
  }, []);

  return (
    <PageContainer heading="Agent List">
      <CmtCard className="mt-6">
        <CmtCardHeader
          className="pt-4"
          title="Agent Details"
          actions={[
            {
              label: 'Create Agent',
              icon: '+',
              onClick: () => {
                handleAgentModel();
              },
            },
          ]}
          titleProps={{
            variant: 'h4',
            component: 'div',
            className: classes.titleRoot,
          }}
        />

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  Agent Name
                  <br />
                  (Email Address)
                </TableCell>
                <TableCell align="left">Mobile</TableCell>
                <TableCell align="left">Code</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agentList?.map(user => (
                <TableRow key={user._id}>
                  <TableCell align="left">
                    {`${user.first_name} ${user.last_name}`}
                    <br />({user.email})
                  </TableCell>
                  <TableCell align="left">{user.mobile ? user.country_code + user.mobile : ''}</TableCell>
                  <TableCell align="left">{user.agent_code ? user.agent_code : ''}</TableCell>
                  <TableCell align="left">
                    <Switch
                      checked={user.is_agent}
                      onChange={e => activeDeactiveAgentHandle(user, e)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CmtCard>
      <Dialog maxWidth={`sm`} fullWidth open={agentModel} onClose={handleAgentModel}>
        <DialogTitle id="alert-dialog-slide-title">Add new agent</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email_agent"
            name="email_agent"
            label="Enter agent email address"
            onChange={agentEmail}
            value={email}
            type="email"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgentModel} color="warning">
            Cancel
          </Button>
          <Button onClick={handleAgentInvite} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default Agent;
