import React from 'react';
import { Route } from 'react-router';
import CustomerList from '../../modules/UserDetails';
import CustomerDetails from '../../modules/UserDetails/Details';
import EarningList from '../../modules/UserDetails/EarningList';
import Tranactions from '../../modules/UserDetails/Tranactions';
import AgentList from '../../modules/Agent';
import CouponList from '../../modules/Coupon';
import BillingDetails from '../../modules/BillingDetails';
import Licences from '../../modules/Licence';
const AppLayout = () => {
    return (
        <>
            <Route path="/dashboard" component={CustomerList} />
            <Route path="/customers" component={CustomerList} />
            <Route path="/earnings" component={EarningList} />
            <Route path="/transactions/:customer_id" component={Tranactions} />
            <Route path="/customers-details/:customer_id" component={CustomerDetails} />
            <Route path="/agent/list" component={AgentList} />
            <Route path="/coupon/list" component={CouponList} />
            <Route path="/billing/list" component={BillingDetails} />
            <Route path="/licences" component={Licences} />
        </>
    );
};

export default AppLayout;
