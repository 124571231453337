import { GET_ALL_CUSTOMERS, GET_CUSTOMER_DATA, GET_EARNING_LIST, GET_AGENT_LIST } from '../../@jumbo/constants/ActionTypes';

export const setAllCustomers = user => {
  return dispatch => {
    dispatch({
      type: GET_ALL_CUSTOMERS,
      payload: user,
    });
  };
};

export const setCustomerData = user => {
  return dispatch => {
    dispatch({
      type: GET_CUSTOMER_DATA,
      payload: user,
    });
  };
};

export const setEarningList = user => {
  return dispatch => {
    dispatch({
      type: GET_EARNING_LIST,
      payload: user,
    });
  };
};

export const setAgentList = user => {
  return dispatch => {
    dispatch({
      type: GET_AGENT_LIST,
      payload: user,
    });
  };
};