import axios from 'util/Api';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { setAuthUser, setInitUrl, setForgetPassMailSent, updateLoadUser } from '../../redux/actions/Auth';
import React from 'react';


export const onRegister= ({ name, email, password }) => {
  return dispatch => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(fetchSuccess());
      const user = { name: name, email: email, password: password };
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(setAuthUser(user));
    }, 300);
  };
};

export const onLogin = (post) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
        .post('login', post,{
          headers:{
            platform:"admin"
          }
        })
        .then(({ data }) => {
            if (data && data.result && data.success) {
                dispatch(fetchSuccess());
                localStorage.setItem('user', JSON.stringify(data.result));
                localStorage.setItem('token', data.result.token);
                dispatch(setAuthUser(data.result));
            } else {                
                dispatch(fetchError(data.error));
            }
        })
        .catch((error) => {
          dispatch(fetchError(error.message));
        });        
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  }

export const onLogout = () => {
  return dispatch => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(fetchSuccess());
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      dispatch(setAuthUser(null));
      dispatch(setInitUrl("/signin"));
    }, 300);
  };
};
  

export const onForgotPassword = () => {
  return dispatch => {
    dispatch(fetchStart());

    setTimeout(() => {
      dispatch(setForgetPassMailSent(true));
      dispatch(fetchSuccess());
    }, 300);
  };
}

export const getSocialMediaIcons = () => {
  return <React.Fragment> </React.Fragment>;
}

export const getAuthUser = (loaded = false) => {
  return dispatch => {
    dispatch(fetchStart());
    dispatch(updateLoadUser(loaded));

    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))));
    }, 300);
  };
};

