import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import { Grid, Box, List, ListItem } from '@material-ui/core';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import SwipeableViews from 'react-swipeable-views';
import CmtButtons from '@coremat/CmtButtons';
import PropTypes from 'prop-types';

// import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { getCustomerData, getCouponList, createPaymentLink, getLatestLicence } from '../../../services/customers';



const useStyles = makeStyles(theme => ({
  orderLg2: {
    [theme.breakpoints.up('lg')]: {
      order: 2,
    },
  },
  orderLg1: {
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
  root: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  cellWidth: {
    width:'20%'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

//constant for Modal Popup
const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func
};


const Tranactions = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams();

  const [paymentLinkModel, setPaymentLinkModel] = React.useState(false);
  const [coupon, setCoupon] = React.useState('');
  const [duration, setDuration] = React.useState('monthly');
  const [countUser, setCountUser] = React.useState(0);
  const [minUser, setMinUser] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [couponList, setCouponList] = React.useState([]);
  const [lastLicence, setLastLicence] = React.useState({});

  const handleClickHandle = () => {
    setPaymentLinkModel(!paymentLinkModel);
  };

  const handleSendLink = () => {
    const insObj = {
      coupon_id: coupon,
      duration,
      number_of_user: countUser,
      user_id: params.customer_id,
    };

    dispatch(
      createPaymentLink(insObj, rsData => {
        setPaymentLinkModel(!paymentLinkModel);
      }),
    );
  };

  const changeDuration = e => {
    setDuration(e.target.value);
    getCoupon({
      customer_id: params.customer_id,
      duration: e.target.value,
      count: countUser,
      purches_type: customerData && customerData.licence ? 'renew' : 'fresh',
    });
  };

  const changeCountUser = e => {
    let minUser = customerData?.licence?.users?.length ? customerData?.licence?.users?.length : 0;
    minUser += customerData?.licence?.invition_hold_count ? customerData?.licence?.invition_hold_count : 0;
    // if (minUser <= Number(e.target.value)) {
    setCountUser(e.target.value);
    getCoupon({
      customer_id: params.customer_id,
      duration: duration,
      count: e.target.value,
      purches_type: customerData && customerData.licence ? 'renew' : 'fresh',
    });
    // }
  };

  const handleChangeCoupon = event => setCoupon(event.target.value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const token = localStorage.getItem('token');
  const userData = jwt.decode(token);

  const customerData = useSelector(state => state?.customer?.customer_data);
  useEffect(() => {
    dispatch(getCustomerData(params.customer_id));
    /* dispatch(
      getLatestLicence(params.customer_id, resData => {
        console.log(resData, "resData");
        setLastLicence(resData);
      }),
    ); */
    getCoupon({
      customer_id: params.customer_id,
      duration: 'monthly',
      count: countUser,
      purches_type: customerData && customerData.licence ? 'renew' : 'fresh',
    });
    return () => {};
  }, [dispatch, params.customer_id]);

  useEffect(() => {
    let minUser = customerData?.licence?.users?.length ? customerData?.licence?.users?.length : 0;
    minUser += customerData?.licence?.invition_hold_count ? customerData?.licence?.invition_hold_count : 0;
    setCountUser(minUser);
    setMinUser(minUser);
    getCoupon({
      customer_id: params.customer_id,
      duration: duration,
      count: minUser,
      purches_type: customerData && customerData.licence ? 'renew' : 'fresh',
    });
  }, [customerData]);

  const getCoupon = bodyData => {
    dispatch(getCouponList(bodyData, resData => setCouponList(resData)));
  };

  return (
    <PageContainer heading="Users Details">
      <Grid container spacing={2}>
        <Grid item align="right" xs={12}>
          <Button variant="contained" color="primary" onClick={handleClickHandle}>
            + Payment Link
          </Button>
        </Grid>
      </Grid>

      <CmtCard>
        <CmtCardHeader
          className="pt-4"
          title="Users Details"
          titleProps={{
            variant: 'h4',
            component: 'div',
            className: classes.titleRoot,
          }}></CmtCardHeader>

        <Box px={6} mb={2}>

          <Grid container spacing={2}>
              <Grid item xs={2}>
              <Typography component="div" variant="h6">
                Company Name:
              </Typography>
              </Grid>
            <Grid item xs={4}>
              {customerData?.customerData?.company_name}
            </Grid>
              <Grid item xs={2}>
              <Typography component="div" variant="h6">
                Account Owner:
              </Typography>
              </Grid>
              <Grid item xs={4}>{customerData?.customerData?.first_name} {customerData?.customerData?.last_name}</Grid>
              <Grid item xs={2}>
                <Typography component="div" variant="h6">Company Email ID:</Typography>
              </Grid>
              <Grid item xs={4}>{customerData?.customerData?.email}</Grid>
              <Grid item xs={2}>
                <Typography component="div" variant="h6">User Email ID:</Typography>
              </Grid>
              <Grid item xs={4}>{customerData?.customerData?.email}</Grid>
              <Grid item xs={2}>
                <Typography component="div" variant="h6">Mobile No:</Typography>
              </Grid>
              <Grid item xs={4}> ({customerData?.customerData?.country_code}) {customerData?.customerData?.mobile}</Grid>
              <Grid item xs={2}>
                <Typography component="div" variant="h6">Mobile No:</Typography>
              </Grid>
              <Grid item xs={4}> ({customerData?.customerData?.country_code}) {customerData?.customerData?.mobile}</Grid>
          </Grid>
        </Box>
          
        <Box px={6} mb={4}>          
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography component="span" variant="h6">License ID:</Typography>
              {customerData?.licence?._id}
            </Grid>
            <Grid item xs={2}>
              <Typography component="span" variant="h6">Licenses:</Typography>
              {customerData?.licence?.count}
            </Grid>
            <Grid item xs={2}>
              <Typography component="span" variant="h6">Recurring:</Typography>
                {(customerData?.licence?.is_recurring) ? "Yes" : "No"}
            </Grid>
            <Grid item xs={2}>
              <Typography component="span" variant="h6">Plan:</Typography>
              {customerData?.licence?.period?.[0]?.name}
            </Grid>
            <Grid item xs={2}>
              <Typography component="span" variant="h6">Expiry:</Typography>
                {moment(new Date(customerData?.licence?.end_date)).format("DD-MM-YYYY")}
            </Grid>
      
          </Grid>
        </Box>
      </CmtCard>

      <Grid container spacing={4}>
        <Grid item xs={8}>
          <CmtCard className="mt-6">
            <CmtCardHeader
              className="pt-4"
              title="Billing Details"
              titleProps={{
                variant: 'h4',
                component: 'div',
                className: classes.titleRoot,
              }}>
            </CmtCardHeader>
            <Box px={6} mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">Billing Name:</Typography>
                </Grid>
                <Grid item xs={9}>{customerData?.billingInfo?.billing_name}</Grid>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">Legal Name:</Typography>
                </Grid>
                <Grid item xs={9}> {customerData?.billingInfo?.legal_name}</Grid>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">Address:</Typography>
                </Grid>
                <Grid item xs={9}>{customerData?.billingInfo?.address}</Grid>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">Email ID:</Typography>
                </Grid>
                <Grid item xs={9}>{customerData?.billingInfo?.email}</Grid>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">Contact No:</Typography>
                </Grid>
                <Grid item xs={9}>{customerData?.billingInfo?.contact_no}</Grid>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">GSTIN:</Typography>
                </Grid>
                <Grid item xs={9}>{customerData?.billingInfo?.gsitn}</Grid>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">GSTIN type:</Typography>
                </Grid>
                <Grid item xs={9}>Regular</Grid>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">PAN:</Typography>
                </Grid>
                <Grid item xs={9}>{customerData?.billingInfo?.pan_card}</Grid>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">TAN:</Typography>
                </Grid>
                <Grid item xs={9}>{customerData?.billingInfo?.tan}</Grid>
                <Grid item xs={3}>
                  <Typography component="div" variant="h6">Registered in SEZ:</Typography>
                </Grid>
                <Grid item xs={9}>{(customerData?.billingInfo?.is_sez) ? "Yes" : "No"}</Grid>
            </Grid>
            </Box>
          </CmtCard>
        </Grid>

        {/* <Grid item xs={4}>
          <CmtCard className="mt-6">
            <CmtCardHeader
              className="pt-4"
              title="Bank Details"
              titleProps={{
                variant: 'h4',
                component: 'div',
                className: classes.titleRoot,
              }}>
               
            </CmtCardHeader>
            
            <Box px={6} mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography component="div" variant="h6">Account Name:</Typography>
                </Grid>
                <Grid item xs={6}>Livefield Technologies</Grid>
                <Grid item xs={6}>
                  <Typography component="div" variant="h6">Bank A/C No.:</Typography>
                </Grid>
                <Grid item xs={6}>44418628985</Grid>
                <Grid item xs={6}>
                  <Typography component="div" variant="h6">Bank Name:</Typography>
                </Grid>
                <Grid item xs={6}>Kotak Mahindra Bank</Grid>
                <Grid item xs={6}>
                  <Typography component="div" variant="h6">IFSC Code:</Typography>
                </Grid>
                <Grid item xs={6}>KKBK0002848</Grid>
                <Grid item xs={6}>
                  <Typography component="div" variant="h6">Branch Name:</Typography>
                </Grid>
                <Grid item xs={6}>Adajan, Surat</Grid>
                
            </Grid>
            </Box>
          </CmtCard>

        </Grid> */}
      </Grid>

      <CmtCard className="mt-6">
        <CmtCardHeader
        className="pt-4"
        title="Financial Details"
        titleProps={{
          variant: 'h4',
          component: 'div',
          className: classes.titleRoot,
        }}
        />

      <Paper className={classes.root}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
        <Tab label="Invoice" {...a11yProps(0)} />
          {/* 
          <Tab label="Credit Notes" {...a11yProps(1)} />
          <Tab label="TDS" {...a11yProps(2)} /> */}
          <Tab label="Transactions" {...a11yProps(1)} />
        </Tabs>
      </Paper>

      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0}>
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead >
                <TableRow>
                  <TableCell align="left" className={classes.cellWidth}>Invoice No</TableCell>
                  <TableCell align="center">Invoce<br/>Date</TableCell>
                  <TableCell align="center">Order<br/>Id</TableCell>
                  <TableCell align="center">Payment<br/>Id</TableCell>
                  <TableCell align="center">Invoice Amount</TableCell>
                  <TableCell align="center">CGST</TableCell>
                  <TableCell align="center">SGST</TableCell>
                  <TableCell align="center">IGST</TableCell>
                  <TableCell align="center">Total Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerData?.transactions?.map(invoiceRows => (
                  <TableRow key={'inv_'+invoiceRows._id}>
                    
                    <TableCell align="left">{invoiceRows.invoice_id}</TableCell>
                    <TableCell align="center">{moment(invoiceRows.createdAt).format("YYYY-MM-DD")}</TableCell>
                    <TableCell align="center">{invoiceRows.razorpay_order_id}</TableCell>
                    <TableCell align="center">{invoiceRows.payment_id}</TableCell>
                    <TableCell align="center">{(invoiceRows.amount - invoiceRows.tax.TOTAL)?.toFixed(2)}</TableCell>
                    <TableCell align="center">{(invoiceRows.tax.CGST).toFixed(2)}</TableCell>
                    <TableCell align="center">{(invoiceRows.tax.SGST).toFixed(2)}</TableCell>
                    <TableCell align="center">{(invoiceRows.tax.IGST).toFixed(2)}</TableCell>
                    <TableCell align="center">{(invoiceRows.tax.TOTAL).toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        {/* 

        <TabPanel value={value} index={1}>
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead >
                <TableRow>
                  <TableCell align="center">Credit Note Status</TableCell>
                  <TableCell align="center">Credit Note No</TableCell>
                  <TableCell align="center">Invoice No</TableCell>
                  <TableCell align="center">Transaction no</TableCell>
                  <TableCell align="center">Amount Refunded</TableCell>
                  <TableCell align="center">Refund Request Date</TableCell>
                  <TableCell align="center">Ticket No</TableCell>
                  <TableCell align="center">Refund date</TableCell>
                  <TableCell align="center">Reason code</TableCell>
                  <TableCell align="center">Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {creditNoteRows.map(creditNoteRows => (
                  <TableRow key={creditNoteRows.cn_no}>
                    <TableCell component="th" scope="row" align="center">{creditNoteRows.cn_status}</TableCell>
                    <TableCell align="center">{creditNoteRows.cn_no}</TableCell>
                    <TableCell align="center">{creditNoteRows.inv_no}</TableCell>
                    <TableCell align="center">{creditNoteRows.transaction}</TableCell>
                    <TableCell align="center">{creditNoteRows.amt_refunded}</TableCell>
                    <TableCell align="center">{creditNoteRows.refund_req_date}</TableCell>
                    <TableCell align="center">{creditNoteRows.ticket_no}</TableCell>
                    <TableCell align="center">{creditNoteRows.refund_date}</TableCell>
                    <TableCell align="center">{creditNoteRows.reason_code}</TableCell>
                    <TableCell align="center">{creditNoteRows.remarks}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

       
        
        <TabPanel value={value} index={2}>
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead >
                <TableRow>
                  <TableCell align="center">TDS Status</TableCell>              
                  <TableCell align="center">Invoice No</TableCell>
                  <TableCell align="center">Invoice Amount</TableCell>
                  <TableCell align="center">TDS Amount</TableCell>
                  <TableCell align="center">Ticket No</TableCell>
                  <TableCell align="center">TDS deduction date</TableCell>
                  <TableCell align="center">TDS Refunded date</TableCell>
                  <TableCell align="center">Transaciton</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tdsRows.map(tdsRows => (
                  <TableRow key={tdsRows.inv_no}>
                    <TableCell component="th" scope="row" align="center">{tdsRows.tds_status}</TableCell>
                    <TableCell align="center">{tdsRows.inv_no}</TableCell>
                    <TableCell align="center">{tdsRows.inv_amt}</TableCell>
                    <TableCell align="center">{tdsRows.tds_amt}</TableCell>
                    <TableCell align="center">{tdsRows.ticket_no}</TableCell>
                    <TableCell align="center">{tdsRows.tds_date}</TableCell>
                    <TableCell align="center">{tdsRows.tds_refunded_date}</TableCell>
                    <TableCell align="center">{tdsRows.transaction}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel> */}

        <TabPanel value={value} index={1}>
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead >
                <TableRow>
                  <TableCell align="left">Status</TableCell>              
                  <TableCell align="center">Transaction No</TableCell>
                  <TableCell align="center">Order ID</TableCell>
                  <TableCell align="center">Transaciton date</TableCell>
                  <TableCell align="center">Payment Method</TableCell>
                  <TableCell align="center">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerData?.transactions?.map(transactionRows => (
                  <TableRow key={transactionRows._id}>
                    <TableCell component="th" scope="row" align="left">{transactionRows.status}</TableCell>
                    <TableCell align="center">{transactionRows.invoice_id}</TableCell>
                    <TableCell align="center">{transactionRows.razorpay_order_id}</TableCell>
                    <TableCell align="center">{moment(new Date(transactionRows.createdAt)).format("DD-MM-YYYY")}</TableCell>
                    <TableCell align="center">{transactionRows.gateway_type}</TableCell>
                    <TableCell align="center">{transactionRows.gateway_amount?.toFixed(2)}</TableCell>  
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

      </SwipeableViews>
      </CmtCard>

      <CmtCard className="mt-6">
        <CmtCardHeader
        className="pt-4"
        title="User Under A/C Owner (2)"
        titleProps={{
          variant: 'h4',
          component: 'div',
          className: classes.titleRoot,
        }}>
        
        </CmtCardHeader>

        <Paper className={classes.root}>
          <TableContainer>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead >
                  <TableRow>
                    <TableCell align="left">ID</TableCell>
                    <TableCell align="left">Name</TableCell>
                    
                    <TableCell align="left">Email</TableCell> 
                    <TableCell align="left">Mobile</TableCell>
                    
                  </TableRow>
                </TableHead>  
                <TableBody>
                  {customerData?.licence?.user_list?.map(userRows => (
                    <TableRow key={userRows.users.id}>
                      <TableCell component="th" scope="row" align="left">{userRows.users._id}
                      </TableCell>
                      <TableCell align="left">{userRows.users.first_name} {userRows.users.last_name}</TableCell>
                      <TableCell align="left">{userRows.users.email}</TableCell>
                      <TableCell align="left">{userRows.users.country_code} {userRows.users.mobile}</TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Paper>
      </CmtCard>

      
      <Dialog open={paymentLinkModel} onClose={handleClickHandle}>
        <DialogTitle>Generate Payment Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To renew or purches livefield subscription, please enter number of licence.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="number_of_user"
            name="number_of_user"
            label="Enter number of user"
            onChange={changeCountUser}
            value={countUser}
            type="number"
            fullWidth
            variant="outlined"
          />
          {minUser > countUser && (
            <Typography style={{color:"rgb(255 152 0)"}}>
              If you reduce number of users, then your subscription will be lost and no refund will be given.
            </Typography>
          )}
          <RadioGroup row aria-label="position" name="duration" value={duration} onChange={changeDuration}>
            <FormControlLabel value="monthly" control={<Radio color="primary" />} label="Monthly" />
            <FormControlLabel value="quaterly" control={<Radio color="primary" />} label="Quaterly" />
            <FormControlLabel value="yearly" control={<Radio color="primary" />} label="Yearly" />
          </RadioGroup>

          <Select name="coupon" fullWidth className="form-control" value={coupon} onChange={handleChangeCoupon}>
            {couponList?.map(cl => {
              return <MenuItem value={cl._id}>{cl.code}</MenuItem>;
            })}
          </Select>

          {/* {couponList && couponList.length > 0 ? (
            <RadioGroup row aria-label="position" name="coupon" value={coupon} onChange={handleChangeCoupon}>
              {couponList.map(cl => {
                return <FormControlLabel value={cl._id} control={<Radio color="primary" />} label={cl.code} />;
              })}
            </RadioGroup>
          ) : (
            ''
          )} */}
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={handleClickHandle}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={handleSendLink}>Send Link</Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default Tranactions;
