import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Grid, Button, List, ListItem } from '@material-ui/core';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from 'react-select';
import moment from 'moment';
import { getEarningList, getAgentList } from '../../../services/customers';
import jwt from 'jsonwebtoken';

const useStyles = makeStyles(theme => ({
  orderLg2: {
    [theme.breakpoints.up('lg')]: {
      order: 2,
    },
  },
  orderLg1: {
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
  root: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const EarningList = props => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams();
  const [filterModel, setFilterModel] = useState(false);
  const [postData, setPostData] = useState({
    user_id: [],
    from_date: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    to_date: moment()
      .endOf('month')
      .format('YYYY-MM-DD'),
  });
  const handleFilterModel = () => setFilterModel(!filterModel);
  const token = localStorage.getItem('token');
  const userData = jwt.decode(token);

  const earningList = useSelector(state => state?.customer?.earning_list);
  const agentList = useSelector(state => state?.customer?.agent_list);

  const handleUsersChange = users => {
    setPostData({
      ...postData,
      user_id: users?.map(u => u.value),
    });
  };

  const handleFromDateChange = date => {
    setPostData({
      ...postData,
      from_date: date ? date.format('YYYY-MM-DD') : null,
    });
  };

  const handleToDateChange = date => {
    setPostData({
      ...postData,
      to_date: date ? date.format('YYYY-MM-DD') : null,
    });
  };

  useEffect(() => {
    dispatch(getEarningList(postData));
    dispatch(getAgentList());
    return () => {};
  }, [dispatch]);

  const handleCouponCreate = () => {
    dispatch(getEarningList(postData));
  };

  return (
    <PageContainer heading="Customer Details">
      <CmtCard className="mt-6">
        <CmtCardHeader
          className="pt-4"
          title="Customer Details"
          actions={[
            {
              label: 'Filter',
              icon: '',
              onClick: () => {
                handleFilterModel();
              },
            },
          ]}
          titleProps={{
            variant: 'h4',
            component: 'div',
            className: classes.titleRoot,
          }}
        />

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell width={100} align="left">
                  Company Name
                  <br />
                  (Email Address)
                </TableCell>
                <TableCell style={{ minWidth: 200 }} align="left">
                  Account Owner
                  <br />
                  (Email - Address)
                </TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell style={{ minWidth: 180 }} align="center">
                  Amount
                  <br />
                  (Without GST)
                </TableCell>
                <TableCell style={{ minWidth: 100 }} align="center">
                  Commision
                </TableCell>
                <TableCell style={{ minWidth: 140 }} align="center">
                  Total Amount
                </TableCell>
                <TableCell style={{ minWidth: 130 }} align="center">
                  Payment
                  <br />
                  Date
                </TableCell>
                <TableCell style={{ minWidth: 140 }} align="center">
                  Invoice No
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {earningList &&
                earningList?.map(transaction => (
                  <TableRow key={transaction._id}>
                    <TableCell align="left">
                      {`${transaction.owned_by.first_name} ${transaction.owned_by.last_name}`}
                      <br />({transaction.owned_by.email})
                    </TableCell>

                    <TableCell align="left">
                      {`${transaction.owned_by.first_name} ${transaction.owned_by.last_name}`}
                      <br />({transaction.owned_by.email})
                    </TableCell>

                    <TableCell align="center">{transaction.transaction.type}</TableCell>

                    <TableCell align="center">
                      {Number(transaction.transaction.amount - transaction.transaction.tax.TOTAL).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      {Number(((transaction.transaction.amount - transaction.transaction.tax.TOTAL) * 10) / 100).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">{Number(transaction.transaction.amount).toFixed(2)}</TableCell>
                    <TableCell align="center">{moment(transaction.transaction.createdAt).format('YYYY-MM-DD')}</TableCell>
                    <TableCell align="center">{transaction.transaction.invoice_id}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CmtCard>
      <Dialog maxWidth={`sm`} fullWidth open={filterModel} onClose={handleFilterModel}>
        <DialogTitle id="alert-dialog-slide-title">Filter earnings</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                placeholder="Select user"
                isMulti
                name="users"
                onChange={handleUsersChange}
                defaultValue={
                  postData?.users?.length > 0
                    ? agentList
                        .filter(u => postData?.users.includes(u._id))
                        .map(u => {
                          return { label: u.email, value: u._id };
                        })
                    : []
                }
                options={agentList?.map(u => {
                  return { label: u.email, value: u._id };
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                fullWidth
                format="yyyy/MM/DD"
                margin="dense"
                id="from_date"
                label="From date"
                value={
                  postData.from_date
                    ? postData.from_date
                    : moment()
                        .startOf('month')
                        .format('YYYY-MM-DD')
                }
                onChange={handleFromDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'from date',
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                fullWidth
                format="yyyy/MM/DD"
                margin="dense"
                id="to_date"
                label="To date"
                value={
                  postData.to_date
                    ? postData.from_date
                    : moment()
                        .endOf('month')
                        .format('YYYY-MM-DD')
                }
                onChange={handleToDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'To date',
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFilterModel} color="warning">
            Cancel
          </Button>
          <Button onClick={handleCouponCreate} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default EarningList;
