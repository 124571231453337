import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Grid, Box, List, ListItem, InputLabel } from '@material-ui/core';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import Switch from '@material-ui/core/Switch';
import Select from 'react-select';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import '../../../theme/css/custom.scss';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2';
import moment from 'moment';
import {
  getUserWhichNotInBillingInfo,
  createBilling,
  deleteBillingAddress,
  getAllBillingInfos
} from '../../../services/customers';
import jwt from 'jsonwebtoken';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '& .MuiInputLabel-root': {
      zIndex:'0'
    }
  },
}));

const BillingDetails = props => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [billingModel, setBillingModel] = useState(false);
  const [userList, setUserList] = useState([]);
  const [billingList, setBillingList] = useState([]);
  const [postData, setPostData] = useState({});
  const [billingData, setBillingData] = useState({}); 

  const token = localStorage.getItem('token');
  const userData = jwt.decode(token);
  const handleBillingModel = () => setBillingModel(!billingModel);

  const handleUsersChange = users => {
    setPostData({
      ...postData,
      user_id: users?.value,
    });
  };

  const handleChange = e => {
    setPostData({
      ...postData,
      [e.target.name]: e.target.value,
    });
  };

  const getBillingList = () => {
    dispatch(
      getAllBillingInfos(resData => {
        setBillingList(resData);
      }),
    );
  };

  const handleBillingCreate = () => {
    if (postData._id && postData._id != '') {
      dispatch(
        createBilling(postData, resData => {
          // get all coupon
          setBillingModel(false);
          setPostData({});
          getBillingList();
        }),
      );
    } else {
      dispatch(
        createBilling(postData, resData => {
          // get all coupon
          setBillingModel(false);
          setPostData({});
          getBillingList();
        }),
      );
    }
  };

  useEffect(() => {
    getBillingList();
    dispatch(
      getUserWhichNotInBillingInfo(resData => {
        setUserList(resData);
      }),
    );
    return () => {};
  }, [dispatch]);

  const setBillingDataHandle = billingDataRs => {
    setBillingData(billingDataRs);
    handleBillingModel();
    billingDataRs.gst_or_pan = billingDataRs.gsitn ? 'gstin' : 'pan';
    billingDataRs.is_sez = billingDataRs.is_sez ? 'Yes' : 'No';
    setPostData(billingDataRs);
  };

  const deleteBillingHandle = cpData => {
    Swal.fire({
      title: 'Do you want to delete this billing address?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: '#d10e0e',
      confirmButtonText: 'Delete',
      denyButtonText: `No`,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          deleteBillingAddress(cpData._id, resData => {
            Swal.fire('Deleted!', '', 'success');
            getBillingList();
          }),
        );
      } else if (result.isDenied) {
        Swal.fire('Address are not deleted', '', 'info');
      }
    });
  };

  return (
    <PageContainer heading="Billing List">
      <CmtCard className="mt-6">
        <CmtCardHeader
          className="pt-4"
          title="Billing Details"
          actions={[
            {
              label: 'Create Billing Details',
              icon: '+',
              onClick: () => {
                handleBillingModel();
                setPostData({});
              },
            },
          ]}
          titleProps={{
            variant: 'h4',
            component: 'div',
            className: classes.titleRoot,
          }}
        />

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: '180px' }} align="left">
                  User
                </TableCell>
                <TableCell style={{ minWidth: '180px' }} align="left">
                  Billing Name
                </TableCell>
                <TableCell align="left">Legal Name</TableCell>
                <TableCell style={{ minWidth: '250px' }} align="left">
                  Address
                </TableCell>
                <TableCell align="left">Contact</TableCell>
                <TableCell style={{ minWidth: '129px' }} align="left">
                  PAN / GSTIN
                </TableCell>
                <TableCell align="left">SEZ</TableCell>
                <TableCell style={{ minWidth: '120px' }} align="left">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billingList?.map(billingRow => (
                <TableRow key={billingRow._id}>
                  <TableCell
                    style={{ fontSize: '13px', paddingRight: '10px' }}
                    align="left">{`${billingRow.user_data.first_name} ${billingRow.user_data.last_name}`}</TableCell>
                  <TableCell style={{ fontSize: '13px', paddingRight: '10px' }} align="left">
                    {billingRow.billing_name}
                  </TableCell>
                  <TableCell style={{ fontSize: '13px', paddingRight: '10px' }} align="left">
                    {billingRow.legal_name}
                  </TableCell>
                  <TableCell
                    style={{ fontSize: '13px', paddingRight: '10px' }}
                    align="left">{`${billingRow.address}, ${billingRow.city}, ${billingRow.state}, ${billingRow.country}, ${billingRow.zipcode}`}</TableCell>
                  <TableCell style={{ fontSize: '13px', paddingRight: '10px' }} align="left">
                    {billingRow.contact_no}
                    <br />
                    {billingRow.email}
                  </TableCell>
                  <TableCell style={{ fontSize: '13px', paddingRight: '10px' }} align="left">
                    {billingRow.gsitn ? billingRow.gsitn : billingRow.pan_card}
                  </TableCell>
                  <TableCell style={{ fontSize: '13px', paddingRight: '10px' }} align="left">
                    {billingRow.is_sez ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell style={{ fontSize: '13px', paddingRight: '10px' }} align="left">
                    <IconButton size="small">
                      <DeleteIcon onClick={() => deleteBillingHandle(billingRow)} fontSize="small" />
                    </IconButton>
                    <IconButton size="small">
                      <EditIcon onClick={() => setBillingDataHandle(billingRow)} fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CmtCard>
      <Dialog maxWidth={`sm`} fullWidth open={billingModel} onClose={handleBillingModel}>
        <DialogTitle id="alert-dialog-slide-title">Create new billing address</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                placeholder="Select user"
                name="user_id"
                onChange={handleUsersChange}
                styles={{
                  option: (style, state) => {
                    return {
                      ...style,
                      zIndex:2
                    };
                  },
                }}
                defaultValue={
                  postData?.user_id?.length > 0
                    ? userList
                        .filter(u => postData?.user_id == u._id)
                        .map(u => {
                          return { label: u.email, value: u._id };
                        })
                    : []
                }
                options={userList?.map(u => {
                  return { label: u.email, value: u._id };
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="billing_name"
                name="billing_name"
                label="Enter billing name"
                onChange={handleChange}
                value={postData?.billing_name}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="legal_name"
                name="legal_name"
                label="Enter legal name"
                onChange={handleChange}
                value={postData?.legal_name}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="email"
                name="email"
                label="Enter email"
                onChange={handleChange}
                value={postData?.email}
                type="email"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="contact_no"
                name="contact_no"
                label="Enter contact_no"
                onChange={handleChange}
                value={postData?.contact_no}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="address"
                name="address"
                label="Enter address"
                onChange={handleChange}
                value={postData?.address}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="city"
                name="city"
                label="Enter city"
                onChange={handleChange}
                value={postData?.city}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="state"
                name="state"
                label="Enter state"
                onChange={handleChange}
                value={postData?.state}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="country"
                name="country"
                label="Enter country"
                onChange={handleChange}
                value={postData?.country}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="zipcode"
                name="zipcode"
                label="Enter zipcode"
                onChange={handleChange}
                value={postData?.zipcode}
                type="number"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">GST or PAN</FormLabel>
                <RadioGroup
                  row
                  aria-label="gst_or_pan"
                  name="gst_or_pan"
                  value={postData.gst_or_pan}
                  onChange={handleChange}>
                  <FormControlLabel value="gstin" control={<Radio color="primary" />} label="GST Number" />
                  <FormControlLabel value="pan" control={<Radio color="primary" />} label="PAN Number" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {postData.gst_or_pan === 'gstin' ? (
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="gsitn"
                  name="gsitn"
                  label="Enter GST Nomber"
                  onChange={handleChange}
                  value={postData?.gsitn}
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="pan_card"
                  name="pan_card"
                  label="Enter pan number"
                  onChange={handleChange}
                  value={postData?.pan_card}
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">SEZ</FormLabel>
                <RadioGroup row aria-label="is_sez" name="is_sez" value={postData.is_sez} onChange={handleChange}>
                  <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBillingModel} color="warning">
            Cancel
          </Button>
          <Button onClick={handleBillingCreate} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default BillingDetails;
