import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Grid, Box, List, ListItem, InputLabel } from '@material-ui/core';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import Switch from '@material-ui/core/Switch';
import Select from 'react-select';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSpring, animated } from 'react-spring/web.cjs';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2';
import moment from 'moment';
import {
  getLicenceType,
  getAllUser,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  getAllCoupon,
  updateStatusCoupon,
} from '../../../services/customers';

import {
  getLicences,
  createLicence,
  getPeriods
} from '../../../services/Licence';

import jwt from 'jsonwebtoken';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const Licences = props => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [licenceModel, setLicenceModel] = useState(false);
  const [userList, setUserList] = useState([]);
  const [licenceTypeList, setLicenceTypeList] = useState([]);
  const [licenceList, setLicenceList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [postData, setPostData] = useState({});
  const [licenceData, setLicenceData] = useState({});

  const licenceRs = useSelector(state => state?.licence?.licence_list);
  const periodsRs = useSelector(state => state?.licence?.periods);  

  const [durationData, setDurationData] = useState();

  const [purchesType, setPurchesType] = useState([
    { value: 'fresh', label: 'Fresh' },
    { value: 'renew', label: 'Renew' },
    { value: 'both', label: 'Both' },
  ]);

  const [couponFor, setCouponFor] = useState([
    { value: 'customer', label: 'Customer' },
    { value: 'partner', label: 'Partner' },
    { value: 'admin', label: 'Admin' },
    { value: 'all', label: 'All' },
  ]);

  const [discountType, setDiscountType] = useState([
    { value: 'per', label: 'Percentage' },
    { value: 'flat', label: 'Flat' },
  ]);

  const token = localStorage.getItem('token');
  const userData = jwt.decode(token);
  const handleLicenceModel = () => setLicenceModel(!licenceModel);

  const handleUsersChange = users => {
    console.log(users, "users")
    setPostData({
      ...postData,
      user_id: users.value,
    });
  };

  const handleChange = e => {
    setPostData({
      ...postData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePlanChange = plan => {
    setPostData({
      ...postData,
      licence_type: plan?.map(p => p.value),
    });
  };

  const handleDurationChange = val => {
    setPostData({
      ...postData,
      plan_duration: val.value,
    });
  };

  const handlePurcherChange = val => {
    setPostData({
      ...postData,
      purches_type: val?.value,
    });
  };

  const handleCouponForChange = val => {
    setPostData({
      ...postData,
      coupon_for: val?.value,
    });
  };

  const handleDiscountTypeChange = val => {
    setPostData({
      ...postData,
      discount_type: val?.value,
    });
  };

  const handleDateChange = date => {
    setPostData({
      ...postData,
      start_date: date ? date.format('YYYY-MM-DD') : null,
    });
  };

  const getLicenceList = () => {
    dispatch(
      getLicences(resData => {
        setLicenceList(resData);
      }),
    );
  };

  const handleLicenceManage = () => {
    if (postData._id && postData._id != '') {
      dispatch(
        createLicence(postData, resData => {
          // get all coupon
          setLicenceModel(false);
          setPostData({});
          getLicenceList();
        }),
      );
    } else {
      dispatch(
        createLicence(postData, resData => {
          // get all coupon
          setLicenceModel(false);
          setPostData({});
          getLicenceList();
        }),
      );
    }
  };

  useEffect(() => {
    getLicenceList();
    dispatch(
      getAllUser(resData => {
        setUserList(resData);
      }),
    );
    dispatch(getPeriods());
    dispatch(
      getLicenceType(resData => {
        let types = resData
          ?.filter(v => v.licence_name !== 'Trial')
          ?.map(v => {
            return { label: v.licence_name, value: v._id };
          });
        setLicenceTypeList(types);
      }),
    );
    return () => {};
  }, [dispatch]);

  const setLicenceDataHandle = licenceDataRs => {
    setLicenceData(licenceDataRs);
    handleLicenceModel();

    let paymentId =
      licenceDataRs.payment && licenceDataRs.payment.length > 0
        ? licenceDataRs.payment[licenceDataRs.payment.length - 1].payment_id
        : null;

    setPostData({
      _id: licenceDataRs._id,
      user_id: licenceDataRs.owned_by,
      payment_id: paymentId,
      plan_duration: licenceDataRs.licence?.licence_period_id,
      count: licenceDataRs.count,
      start_date: licenceDataRs.start_date,
    });
  };
  console.log(postData, "postData postData")
  const deleteCouponHandle = cpData => {
    Swal.fire({
      title: 'Do you want to delete this licence?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: '#d10e0e',
      confirmButtonText: 'Delete',
      denyButtonText: `No`,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          deleteCoupon(cpData._id, resData => {
            Swal.fire('Deleted!', '', 'success');
            getLicenceList();
          }),
        );
      } else if (result.isDenied) {
        Swal.fire('Coupon are not deleted', '', 'info');
      }
    });
  };

  const handleUpdateState = (cpData, e) => {
    let statusVal = e.target.checked;
    Swal.fire({
      title: `Do you want to ${statusVal ? 'active' : 'De-Active'} this licence?`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: statusVal ? 'Activate' : 'De-Active',
      denyButtonText: `No`,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          updateStatusCoupon({ _id: cpData._id, status: statusVal }, resData => {
            Swal.fire(`${statusVal ? 'Activated' : 'De-Active'}`, '', 'success');
            getLicenceList();
          }),
        );
      } else if (result.isDenied) {
        Swal.fire('Coupon status is not changed', '', 'info');
      }
    });
  };

  return (
    <PageContainer heading="Licence List">
      <CmtCard className="mt-6">
        <CmtCardHeader
          className="pt-4"
          title="Licence Details"
          actions={[
            {
              label: 'Create Licence',
              icon: '+',
              onClick: () => {
                handleLicenceModel();
                setPostData({});
              },
            },
          ]}
          titleProps={{
            variant: 'h4',
            component: 'div',
            className: classes.titleRoot,
          }}
        />

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: '140px' }} align="left">
                  Account Owner
                </TableCell>
                <TableCell align="left">Contact</TableCell>
                <TableCell align="left">Start / End</TableCell>
                <TableCell style={{ minWidth: '140px' }} align="left">
                  Licence Type
                </TableCell>
                <TableCell align="left">No. Licence</TableCell>
                <TableCell align="left">Expired</TableCell>
                <TableCell style={{ minWidth: '120px' }} align="left">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {licenceRs?.map(licenceRow => (
                <TableRow key={licenceRow._id}>
                  <TableCell align="left">
                    {licenceRow.owner.first_name} {licenceRow.owner.last_name}
                  </TableCell>
                  <TableCell align="left">
                    {licenceRow.owner.email}
                    <br />
                    {licenceRow.owner.mobile}
                  </TableCell>
                  <TableCell align="left">
                    {moment(licenceRow.start_date).format('YYYY-MM-DD')} -<br />
                    {moment(licenceRow.end_date).format('YYYY-MM-DD')}
                  </TableCell>
                  <TableCell align="left">
                    {licenceRow.licence.type[0].licence_name} - {licenceRow.licence.period[0].name}
                    {' '}
                    ({licenceRow.licence.period[0].duration} {licenceRow.licence.period[0].type})
                  </TableCell>
                  <TableCell align="left">{licenceRow.count}</TableCell>
                  <TableCell align="left">{licenceRow.is_expired ? 'Yes' : 'No'}</TableCell>
                  {/* <TableCell align="left">
                    <Switch
                      checked={licenceRow.is_active}
                      onChange={e => handleUpdateState(licenceRow, e)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell> */}
                  <TableCell align="left">
                    {/* <IconButton size="small">
                      <DeleteIcon onClick={() => deleteCouponHandle(licenceRow)} fontSize="small" />
                    </IconButton> */}
                    <IconButton size="small">
                      <EditIcon onClick={() => setLicenceDataHandle(licenceRow)} fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CmtCard>
      <Dialog maxWidth={`sm`} fullWidth open={licenceModel} onClose={handleLicenceModel}>
        <DialogTitle id="alert-dialog-slide-title">{postData && postData._id ? 'Update' : 'Create'} licence</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                placeholder="Select user"
                name="user_id"
                onChange={handleUsersChange}
                defaultValue={
                  postData?.user_id?.length > 0
                    ? userList
                        .filter(u => postData?.user_id == u._id)
                        .map(u => {
                          return { label: u.email, value: u._id };
                        })?.[0]
                    : []
                }
                options={userList?.map(u => {
                  return { label: u.email, value: u._id };
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="payment_id"
                name="payment_id"
                label="Enter transaction id"
                onChange={handleChange}
                value={postData?.payment_id}
                // disabled={postData._id && postData._id != ''}
                type="email"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                placeholder="Duration"
                name="plan_duration"
                defaultValue={
                  postData?.plan_duration?.length > 0
                    ? periodsRs.filter(item => postData?.plan_duration == item._id)?.map(p=>{
                      return { value: p._id, label: p.name }
                    })?.[0]
                    : []
                }
                onChange={handleDurationChange}
                options={periodsRs?.map(p=>{
                  return { value: p._id, label: p.name }
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="count"
                name="count"
                label="Enter number of users"
                onChange={handleChange}
                value={postData?.count}
                type="number"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                fullWidth
                format="yyyy/MM/DD"
                margin="dense"
                id="start_date"
                label="Start date"
                value={postData.start_date ? postData.start_date : null}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'start date',
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLicenceModel} color="warning">
            Cancel
          </Button>
          <Button onClick={handleLicenceManage} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default Licences;
