import { GET_ALL_LICENCE, GET_ALL_PERIODS } from '../../@jumbo/constants/ActionTypes';

export const setAllLicences = licences => {
    return dispatch => {
      dispatch({
        type: GET_ALL_LICENCE,
        payload: licences,
      });
    };
};

export const setLicencesPeriods = periods => {
    return dispatch => {
        dispatch({
          type: GET_ALL_PERIODS,
          payload: periods,
        });
      };
}