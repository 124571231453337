import axios from 'util/Api';
import { handleError } from 'util/ApiHelper';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { setAllLicences, setLicencesPeriods } from '../../redux/actions/Licence';

export const getLicences = user_id => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .get('admin/all_licences', {
            headers: {
              authorization: localStorage.getItem('token'),
              platform: 'admin',
            },
          })
          .then(({ data }) => {
            if (data && data.result && data.success) {
              dispatch(fetchSuccess());
              dispatch(setAllLicences(data.result));
            } else {
              dispatch(setAllLicences([]));
              dispatch(fetchError(data.error));
            }
          })
          .catch(error => {
            dispatch(fetchError(error.message));
            handleError(error);
          });
      } catch (error) {
        dispatch(fetchError(error.message));
        handleError(error);
      }
    };
};


export const getPeriods = user_id => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .get('licence/periods', {
            headers: {
              authorization: localStorage.getItem('token'),
              platform: 'admin',
            },
          })
          .then(({ data }) => {
            if (data && data.result && data.success) {
              dispatch(fetchSuccess());
              dispatch(setLicencesPeriods(data.result));
            } else {
              dispatch(setLicencesPeriods([]));
              dispatch(fetchError(data.error));
            }
          })
          .catch(error => {
            dispatch(fetchError(error.message));
            handleError(error);
          });
      } catch (error) {
        dispatch(fetchError(error.message));
        handleError(error);
      }
    };
};

export const createLicence = (body, cb) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('admin/licence/create', body, {
            headers: {
              authorization: localStorage.getItem('token'),
              platform: 'admin',
            },
          })
          .then(({ data }) => {
            if (data && data.result && data.success) {
              dispatch(fetchSuccess());
              if(typeof cb == "function") cb(data.result);
            } else {
              dispatch(fetchError(data.error));
              if(typeof cb == "function") cb(data.result);
            }
          })
          .catch(error => {
            dispatch(fetchError(error.message));
            handleError(error);
          });
      } catch (error) {
        dispatch(fetchError(error.message));
        handleError(error);
      }
    };
};