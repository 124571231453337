import { GET_ALL_CUSTOMERS, GET_CUSTOMER_DATA, GET_EARNING_LIST, GET_AGENT_LIST } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  customer_list: [],
  customer_data: [],
  earning_list: [],
  agent_list: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CUSTOMERS: {
      return {
        ...state,
        customer_list: action.payload,
      };
    }
    case GET_CUSTOMER_DATA: {
      return {
        ...state,
        customer_data: action.payload,
      };
    }

    case GET_EARNING_LIST: {
      return {
        ...state,
        earning_list: action.payload,
      };
    }

    case GET_AGENT_LIST: {
      return {
        ...state,
        agent_list: action.payload,
      };
    }
    default:
      return state;
  }
};
