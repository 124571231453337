import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Grid, Box, List, ListItem, InputLabel } from '@material-ui/core';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import Switch from '@material-ui/core/Switch';
import Select from 'react-select';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSpring, animated } from 'react-spring/web.cjs';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Swal from 'sweetalert2';
import moment from 'moment';
import {
  getLicenceType,
  getAllUser,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  getAllCoupon,
  updateStatusCoupon,
} from '../../../services/customers';
import jwt from 'jsonwebtoken';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const Coupon = props => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [couponModel, setCouponModel] = useState(false);
  const [userList, setUserList] = useState([]);
  const [licenceTypeList, setLicenceTypeList] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [postData, setPostData] = useState({});
  const [couponData, setCouponData] = useState({});
  const [durationData, setDurationData] = useState([
    { value: 'yearly', label: 'Yearly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'quaterly', label: 'Quaterly' },
  ]);

  const [purchesType, setPurchesType] = useState([
    { value: 'fresh', label: 'Fresh' },
    { value: 'renew', label: 'Renew' },
    { value: 'both', label: 'Both' },
  ]);

  const [couponFor, setCouponFor] = useState([
    { value: 'customer', label: 'Customer' },
    { value: 'partner', label: 'Partner' },
    { value: 'admin', label: 'Admin' },
    { value: 'all', label: 'All' },
  ]);

  const [discountType, setDiscountType] = useState([
    { value: 'per', label: 'Percentage' },
    { value: 'flat', label: 'Flat' },
  ]);

  const token = localStorage.getItem('token');
  const userData = jwt.decode(token);
  const handleCouponModel = () => setCouponModel(!couponModel);

  const handleUsersChange = users => {
    setPostData({
      ...postData,
      user_id: users?.map(u => u.value),
    });
  };

  const handleChange = e => {
    setPostData({
      ...postData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePlanChange = plan => {
    setPostData({
      ...postData,
      licence_type: plan?.map(p => p.value),
    });
  };

  const handleDurationChange = val => {
    setPostData({
      ...postData,
      plan_duration: val?.map(p => p.value),
    });
  };

  const handlePurcherChange = val => {
    setPostData({
      ...postData,
      purches_type: val?.value,
    });
  };

  const handleCouponForChange = val => {
    setPostData({
      ...postData,
      coupon_for: val?.value,
    });
  };

  const handleDiscountTypeChange = val => {
    setPostData({
      ...postData,
      discount_type: val?.value,
    });
  };

  const handleDateChange = date => {
    setPostData({
      ...postData,
      valid_to: date ? date.format('YYYY-MM-DD') : null,
    });
  };

  const getCouponList = () => {
    dispatch(
      getAllCoupon(resData => {
        setCouponList(resData);
      }),
    );
  };

  const handleCouponCreate = () => {
    if (postData._id && postData._id != '') {
      dispatch(
        updateCoupon(postData, resData => {
          // get all coupon
          setCouponModel(false);
          setPostData({});
          getCouponList();
        }),
      );
    } else {
      dispatch(
        createCoupon(postData, resData => {
          // get all coupon
          setCouponModel(false);
          setPostData({});
          getCouponList();
        }),
      );
    }
  };

  const agentList = useSelector(state => state?.customer?.agent_list);
  useEffect(() => {
    getCouponList();
    dispatch(
      getAllUser(resData => {
        setUserList(resData);
      }),
    );
    dispatch(
      getLicenceType(resData => {
        let types = resData
          ?.filter(v => v.licence_name !== 'Trial')
          ?.map(v => {
            return { label: v.licence_name, value: v._id };
          });
        setLicenceTypeList(types);
      }),
    );
    return () => {};
  }, [dispatch]);

  const setCouponDataHandle = couponDataRs => {
    setCouponData(couponDataRs);
    handleCouponModel();
    setPostData({
      _id: couponDataRs._id,
      code: couponDataRs.code,
      users: couponDataRs.user_id,
      licence_type: couponDataRs.licence_type,
      plan_duration: couponDataRs.plan_duration,
      purches_type: couponDataRs.purches_type,
      coupon_for: couponDataRs.coupon_for,
      discount_type: couponDataRs.discount_type,
      discount: couponDataRs.discount,
      valid_to: couponDataRs.valid_to ? moment(couponDataRs.valid_to) : null,
    });
  };

  const deleteCouponHandle = cpData => {
    Swal.fire({
      title: 'Do you want to delete this coupon?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonColor: '#d10e0e',
      confirmButtonText: 'Delete',
      denyButtonText: `No`,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          deleteCoupon(cpData._id, resData => {
            Swal.fire('Deleted!', '', 'success');
            getCouponList();
          }),
        );
      } else if (result.isDenied) {
        Swal.fire('Coupon are not deleted', '', 'info');
      }
    });
  };

  const handleUpdateState = (cpData, e) => {
    let statusVal = e.target.checked;
    Swal.fire({
      title: `Do you want to ${statusVal ? 'active' : 'De-Active'} this coupon?`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: statusVal ? 'Activate' : 'De-Active',
      denyButtonText: `No`,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          updateStatusCoupon({ _id: cpData._id, status: statusVal }, resData => {
            Swal.fire(`${statusVal ? 'Activated' : 'De-Active'}`, '', 'success');
            getCouponList();
          }),
        );
      } else if (result.isDenied) {
        Swal.fire('Coupon status is not changed', '', 'info');
      }
    });
  };

  return (
    <PageContainer heading="Coupon List">
      <CmtCard className="mt-6">
        <CmtCardHeader
          className="pt-4"
          title="Coupon Details"
          actions={[
            {
              label: 'Create Coupon',
              icon: '+',
              onClick: () => {
                handleCouponModel();
                setPostData({});
              },
            },
          ]}
          titleProps={{
            variant: 'h4',
            component: 'div',
            className: classes.titleRoot,
          }}
        />

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: '140px' }} align="left">
                  Coupon Code
                </TableCell>
                <TableCell align="left">Discount</TableCell>
                <TableCell align="left">Created For</TableCell>
                <TableCell style={{ minWidth: '140px' }} align="left">
                  Licence Type
                </TableCell>
                <TableCell align="left">Plan Duration</TableCell>
                <TableCell align="left">Purches Type</TableCell>
                <TableCell align="left">Coupon For</TableCell>
                <TableCell style={{ minWidth: '125px' }} align="left">
                  Valid to
                </TableCell>
                <TableCell style={{ minWidth: '110px' }} align="left">
                  Status
                </TableCell>
                <TableCell style={{ minWidth: '120px' }} align="left">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {couponList?.map(couponRow => (
                <TableRow key={couponRow._id}>
                  <TableCell align="left">{couponRow.code}</TableCell>
                  <TableCell align="left">
                    {couponRow.discount} {couponRow.discount_type === 'per' ? '%' : '₹'}
                  </TableCell>
                  <TableCell align="left">{couponRow.user_data?.map(l => l.email)?.join(', ')}</TableCell>
                  <TableCell align="left">{couponRow.licence_type_data?.map(l => l.licence_name)?.join(', ')}</TableCell>
                  <TableCell align="left">{couponRow.plan_duration?.join(', ')}</TableCell>
                  <TableCell align="left">{couponRow.purches_type}</TableCell>
                  <TableCell align="left">{couponRow.coupon_for}</TableCell>
                  <TableCell align="left">
                    {couponRow.valid_to ? moment(couponRow.valid_to).format('YYYY-MM-DD') : 'Never expired'}
                  </TableCell>
                  <TableCell align="left">
                    <Switch
                      checked={couponRow.is_active}
                      onChange={e => handleUpdateState(couponRow, e)}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <IconButton size="small">
                      <DeleteIcon onClick={() => deleteCouponHandle(couponRow)} fontSize="small" />
                    </IconButton>
                    <IconButton size="small">
                      <EditIcon onClick={() => setCouponDataHandle(couponRow)} fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CmtCard>
      <Dialog maxWidth={`sm`} fullWidth open={couponModel} onClose={handleCouponModel}>
        <DialogTitle id="alert-dialog-slide-title">Create new coupon</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="coupon_code"
                name="code"
                label="Enter coupon code"
                onChange={handleChange}
                value={postData?.code}
                disabled={postData._id && postData._id != ''}
                type="email"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                placeholder="Select user"
                isMulti
                name="users"
                onChange={handleUsersChange}
                defaultValue={
                  postData?.users?.length > 0
                    ? userList
                        .filter(u => postData?.users.includes(u._id))
                        .map(u => {
                          return { label: u.email, value: u._id };
                        })
                    : []
                }
                options={userList?.map(u => {
                  return { label: u.email, value: u._id };
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                placeholder="Plan Type"
                name="licence_type"
                isMulti
                defaultValue={
                  postData?.licence_type?.length > 0
                    ? licenceTypeList.filter(item => postData?.licence_type?.includes(item.value))
                    : []
                }
                onChange={handlePlanChange}
                options={licenceTypeList}
              />
            </Grid>

            <Grid item xs={12}>
              <Select
                placeholder="Duration"
                name="plan_duration"
                isMulti
                defaultValue={
                  postData?.plan_duration?.length > 0
                    ? durationData.filter(item => postData?.plan_duration?.includes(item.value))
                    : []
                }
                onChange={handleDurationChange}
                options={durationData}
              />
            </Grid>

            <Grid item xs={12}>
              <Select
                placeholder="Purchase Type"
                defaultValue={postData.purches_type ? purchesType.filter(item => postData.purches_type == item.value) : null}
                name="purches_type"
                onChange={handlePurcherChange}
                options={purchesType}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                placeholder="Coupon For"
                defaultValue={postData.coupon_for ? couponFor.filter(item => postData.coupon_for == item.value) : null}
                name="coupon_for"
                onChange={handleCouponForChange}
                options={couponFor}
              />
            </Grid>

            <Grid item xs={12}>
              <Select
                placeholder="Discount type"
                name="discount_type"
                defaultValue={
                  postData.discount_type ? discountType.filter(item => postData.discount_type == item.value) : null
                }
                onChange={handleDiscountTypeChange}
                options={discountType}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="discount"
                name="discount"
                label="Enter discount"
                onChange={handleChange}
                value={postData?.discount}
                type="number"
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <KeyboardDatePicker
                disableToolbar
                variant="outlined"
                fullWidth
                format="yyyy/MM/DD"
                margin="dense"
                id="valid_to"
                label="Valid to"
                value={postData.valid_to}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'valid_to',
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCouponModel} color="warning">
            Cancel
          </Button>
          <Button onClick={handleCouponCreate} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default Coupon;
