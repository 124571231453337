import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Grid,Box,List, ListItem } from '@material-ui/core';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import PropTypes from 'prop-types';

import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSpring, animated } from 'react-spring/web.cjs';
import moment from 'moment';
import { getCustomerData } from '../../../services/customers';
import jwt from "jsonwebtoken";

const useStyles = makeStyles(theme => ({
  orderLg2: {
    [theme.breakpoints.up('lg')]: {
      order: 2,
    },
  },
  orderLg1: {
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
  root: {
    flexGrow: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



const UserDetails = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams();

  const token = localStorage.getItem('token');
  const userData = jwt.decode(token);
  
  const customerData = useSelector((state)=> state?.customer?.customer_data);
  console.log(customerData, "customerList")
  useEffect(() => {
    dispatch(getCustomerData(userData.user));
    return () => {
      
    };
  }, []);

  return (
    <PageContainer heading="Customer Details">
      <CmtCard className="mt-6">
        <CmtCardHeader
          className="pt-4"
          title="Customer Details"
          titleProps={{
            variant: 'h4',
            component: 'div',
            className: classes.titleRoot,
          }}
        />

        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead >
              <TableRow>
                <TableCell width={100} align="left">Company Name<br />(Email Address)</TableCell>
                <TableCell style={{minWidth:200}} align="center">Account Owner<br />(Email - Address)</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell style={{minWidth:180}} align="center">Amount<br />(Without GST)</TableCell>
                <TableCell style={{minWidth:100}}align="center">Commision</TableCell>
                <TableCell style={{minWidth:140}} align="center">Total Amount</TableCell>
                <TableCell style={{minWidth:130}} align="center">Payment<br />Date</TableCell>
                <TableCell style={{minWidth:140}} align="center">Transaction ID</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {customerData && customerData?.map(transaction => (
                <TableRow key={transaction._id}>
                  <TableCell component="th" scope="row" align="left">{`${transaction.owned_by.first_name} ${transaction.owned_by.last_name}`}<br />({transaction.owned_by.email})</TableCell>
                  <TableCell component="th" scope="row" align="center">{`${transaction.owned_by.first_name} ${transaction.owned_by.last_name}`}<br />({transaction.owned_by.email})</TableCell>
                  
                  <TableCell align="center">{transaction.type}</TableCell>

                  <TableCell align="center">{Number(transaction.amount - transaction.tax.TOTAL).toFixed(2)}</TableCell>
                  <TableCell align="center">{Number((transaction.amount - transaction.tax.TOTAL) * 10 / 100).toFixed(2)}</TableCell><TableCell align="center">{Number(transaction.amount).toFixed(2)}</TableCell>
                  <TableCell align="center">{moment(transaction.createdAt).format("YYYY-MM-DD")}</TableCell>
                  <TableCell align="center">{transaction.invoice_id}</TableCell>                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        
      </CmtCard>
    </PageContainer>
  );
};

export default UserDetails;
